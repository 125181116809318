body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Digital-7';
  src: url(/static/media/Digital-7.77e49b02.woff) format('woff'),
       url(/static/media/Digital-7.71040613.ttf) format('truetype'),
       url(/static/media/Digital-7.c0a5f59b.svg#Digital-7) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'digital-7';
  src: url(/static/media/digital-7.677acab4.eot);
  src: url(/static/media/digital-7.5a6bb5cd.woff2) format('woff2'),
       url(/static/media/digital-7.677acab4.eot?#iefix) format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

