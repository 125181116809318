body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Digital-7';
  src: url('assets/fonts/digital7/Digital-7.woff') format('woff'),
       url('assets/fonts/digital7/Digital-7.ttf') format('truetype'),
       url('assets/fonts/digital7/Digital-7.svg#Digital-7') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'digital-7';
  src: url('assets/fonts/digital7/digital-7.eot');
  src: url('assets/fonts/digital7/digital-7.woff2') format('woff2'),
       url('assets/fonts/digital7/digital-7.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}